import React from "react";
import { useInView } from 'react-intersection-observer';
import '../styles/accueil.scss';
import prog from "../img/prog.jpg";
import presta from "../img/cercleduvin.jpg";
import word from "../img/wordpress-demo.jpg";
import joom from "../img/kibao.jpg";

export default function Accueil() {
    const {ref: animleftprog, inView: LeftIsVisibleProg } = useInView();
    const {ref: animleftword, inView: LeftIsVisibleWord } = useInView();
    const {ref: animleftpresta, inView: LeftIsVisiblePresta } = useInView();
    const {ref: animleftjoom, inView: LeftIsVisibleJoom } = useInView();
    const {ref: animrightprog, inView: RightIsVisibleProg } = useInView();
    const {ref: animrightword, inView: RightIsVisibleWord } = useInView();
    const {ref: animrightpresta, inView: RightIsVisiblePresta } = useInView();
    const {ref: animrightjoom, inView: RightIsVisibleJoom } = useInView();
    const {ref: animtop, inView: TopIsVisible } = useInView();

   // const {ref: animbottom, inView: BottomIsVisible } = useInView();
   
  
    

        return (
            <div>
                <div ref={animtop} className={`${TopIsVisible ? "leave-enter": 'enter-top'}`}>
                    <h1>
                        CRÉATION DE SITE WEB SUR MESURE
                    </h1>
                    <h2>Travaillons ensemble pour un site à votre image</h2>
                </div>
                <div className="double-tab">
                    <div className="text-accueil prog"> 
                        <div ref={animleftprog} className={`${LeftIsVisibleProg ? 'leave-enter-bounce': 'enter-bounce'}`}>
                            <div>Pour un site sur mesure nous utilisons les langages suivants :</div>
                            <div>
                                <ul>
                                    <li>HMLT5, CSS3, JavaScript</li>
                                    <li>React, VueJs, Angular</li>
                                </ul>
                            </div>
                            <div>Ce site a été créé avec le FrameWork React</div>
                        </div>
                    </div>
                    <div  className="img-prog">
                        <img src={prog} alt={"Programme utilisé"} ref={animrightprog} width={"523px"} height={"364px"} className={ `${RightIsVisibleProg ? "leave-enter": 'enter-right'}`} />
                    </div>
                  
                    <div className="img-word">
                        <a href="https://www.weldeti.fr/wordpress-demo/">
                            <img src={word} alt={"Demo-wordpress"} width={"523px"} height={"364px"} ref={animleftword} className={`${LeftIsVisibleWord ? "leave-enter": 'enter-left'}`} />
                        </a>
                    </div>
                    <div className="text-accueil word"> 
                        <div ref={animrightword} className={`${RightIsVisibleWord ? 'leave-enter-bounce': 'enter-bounce'}`}> 
                            <div>
                                <p>Site de démonstration</p>
                                <p>CMS Wordpress</p>
                                <p><a className="visite" href="https://www.weldeti.fr/wordpress-demo/">Visiter ICI</a></p>
                            </div>
                        </div> 
                    </div>
                    <div className="text-accueil presta">
                        <div ref={animleftpresta} className={`${LeftIsVisiblePresta ? 'leave-enter-bounce': 'enter-bounce'}`}>      
                            <div>
                                <p>Site Cercle Du Vin.</p>
                                <p>Création d'un E-Commerce avec le CMS Prestashop</p>
                                <p><a className="visite" href="https://cercleduvin.fr">Visiter ICI</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="img-presta">    
                        <a href="https://cercleduvin.fr">
                            <img http="https://cercleduvin.fr" src={presta}  width={"523px"} height={"364px"} alt="leveil au monde e-commerce" ref={animrightpresta} className={`${RightIsVisiblePresta ? 'leave-enter': 'enter-right'}`} />
                        </a>
                    </div>
                    <div className="img-joom">
                        <a href="https://www.kibao.fr/">
                            <img src={joom} alt="kibao.fr" width={"523px"} height={"364px"} ref={animleftjoom} className={`${LeftIsVisibleJoom ? "leave-enter": 'enter-left'}`} />
                        </a>
                    </div>
                    <div className="text-accueil joom"> 
                        <div ref={animrightjoom} className={`${RightIsVisibleJoom ? 'leave-enter-bounce': 'enter-bounce'}`}>  
                            <div> 
                                <p>Site de notre partenaire KIBAO</p>
                                <p>Création avec le CMS Joomla</p>
                                <p><a className="visite" href="https://www.kibao.fr/">Visiter ICI</a></p>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        )
    }
