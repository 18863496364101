import React, { useRef } from "react";
import '../styles/contact.scss';
import emailjs from '@emailjs/browser';

export default function Contact() {

    const form = useRef();

    const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_weldeti_contact', 'template_contact', form.current, 'l8-VrqggM4XXdr9ZD')
    .then((result) => {
        alert('Votre message a bien été envoyé')
        document.location.href = '/';
    }, (error) => {
        alert('Désolé votre message n\'a pas pu être envoyé')
    });
};
    return (
    <div>
        <div>
            <h1>
                POUR UNE DEMANDE D'INFORMATION OU DE DEVIS CONTACTEZ-NOUS
            </h1>
            <div>
                Réponse sous 24H
            </div>
            <div className="formulaire">
                <form ref={form} onSubmit={sendEmail}>
                    <div className="form-text">
                        <label for="from_name">Votre Nom*</label>
                        <input type="text" name="from_name" required/>
                    </div>
                    <div className="form-text">
                        <label for="from_mail">Votre adressse E-mail*</label>
                        <input type="mail" name="from_mail" required/>
                    </div>
                    <div className="form-text">
                        <label for="from_tel">Votre Numéro de téléphone</label>
                        <input type="tel" name="from_tel" />
                    </div>
                    <div className="form-text">
                        <label for="message">Votre Demande*</label>
                        <textarea type="text" name="message" required/>
                    </div>
                    <br />
                    <div className="accept-contact">
                        <input type="checkbox" unchecked name="controlled" required></input>
                        <div>En nous transmettant vos informations, vous nous autorisez à vous recontacter.</div>
                    </div>
                    <button type="submit">ENVOYER</button>
                </form>
                <div>* = Champ obligatoire</div>
                
            </div>
        </div>
    </div>
    )
}