import React from "react";
import "../styles/etapes.scss"

export default function Etapes() {
    return (
        <div>
            <h1>
                VOTRE SITE WEB EN 6 ÉTAPES
            </h1>
            <h2>Quelle que soit l'étape nous vous accompagnons du début à la fin du projet</h2>
            <div className="tab-etapes">
                <div className="text-etapes">
                    <div className="text-etape-number">ÉTAPE 1</div>
                    <div className="text-etapes-desc"><p>Faite votre demande</p><p>Nous vous répondrons dans la journée</p></div>
                </div>
                <div className="text-etapes">
                    <div className="text-etape-number">ÉTAPE 2</div>
                    <div className="text-etapes-desc"><p>Discutons ensemble de votre projet</p><p>Avoir les détails de celui-ci nous permettra d'établir un devis juste.</p></div>
                </div>
                <div className="text-etapes">
                    <div className="text-etape-number">ÉTAPE 3</div>
                    <div className="text-etapes-desc">Lancement du projet</div>
                </div>
                <div className="text-etapes">
                    <div className="text-etape-number">ÉTAPE 4</div>
                    <div className="text-etapes-desc">Finalisation du projet</div>
                </div>
                <div className="text-etapes">
                    <div className="text-etape-number">ÉTAPE 5</div>
                    <div className="text-etapes-desc">Remise de clé du site</div>
                </div>
                <div className="text-etapes">
                    <div className="text-etape-number">ÉTAPE 6 (Option)</div>
                    <div className="text-etapes-desc">Assistance offerte pendant 2 mois (Puis 30€/mois)</div>
                </div>
            </div>
        </div>
    )
}