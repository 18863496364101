import React from "react";
import { Link } from "react-router-dom";
import Logo from "../img/Logo-lewd-min.jpg";

export default function Nav() {
    return (
        <div className="nav">
            <div className="logo">
                <img src={Logo} height={"auto"} alt="logo WELDETI" width="100px"/>
            </div>
            <div className="gratuit">
                <p>DEVIS</p> 
                <p>GRATUIT</p>
            </div>
        <nav>
            <Link to="/">Accueil</Link>
            <Link to="/etapes">Étapes</Link>
            <Link to="/contact">Contact</Link>
            <Link to="/support">Support</Link>
        </nav>
       </div>
    )
}