import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
    <div className="Footer-global">
        <div className="partenaire">
                <div>Partenaires</div>
             <div className="lien-partenaire">
                    <ul><a href="https://www.kibao.fr">KIBAO</a></ul>
                    <ul><a href="https://www.pepperi.com">PEPPERI</a></ul>
                    <ul><a href="https://cercleduvin.fr/">CERCLE DU VIN</a></ul>
                    <ul><a href="https://www.sodepardl.com/fr/">SODEPARDL</a></ul>
            </div>
            </div>
            <div class="contact">
                <Link to="/contact">Pour plus d'information contactez-nous</Link>
            </div>
            <div className="coordonees">
                <div>ETIQUE Ludovic</div>
                <div>10000 TROYES</div>
                <div>07.77.78.25.51</div>
                <div>contact@weldeti.fr</div>
            </div>
    </div>  
    )
}