import './App.css';
import {Route, Routes} from 'react-router-dom';
import Accueil from './components/Accueil';
import Contact from './components/Contact';
import Etapes from './components/Etapes';
import Support from './components/Support';
import Nav from './components/nav';
import Footer from './components/footer';


function App() {

  return (
    <div className="App">
      <Nav />
        <Routes>
          <Route path="*" element={<Accueil/>}/>
          <Route path="/" element={<Accueil/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/etapes" element={<Etapes/>}/>
          <Route path="/support" element={<Support/>}/>
        </Routes>
      <Footer />
    </div>
  );
}

export default App;
